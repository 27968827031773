import { FC, useMemo } from 'react';

import clsx from 'clsx';
import xss, {
  IFilterXSSOptions,
  safeAttrValue,
  getDefaultWhiteList,
} from 'xss';

import { XssProps } from './Xss.types';

const Xss: FC<XssProps> = ({ text = '', className, extendWhiteList }) => {
  const defaultOptions = useMemo((): IFilterXSSOptions => {
    return {
      allowList: {
        ...getDefaultWhiteList(),
        ...extendWhiteList,
      },
      safeAttrValue(tag, name, value, cssFilter) {
        if (
          tag === 'a' &&
          name === 'href' &&
          value.startsWith('externalbrowser://')
        ) {
          return value;
        }

        return safeAttrValue(tag, name, value, cssFilter);
      },
    };
  }, [extendWhiteList]);
  const cleanText = useMemo(() => {
    return xss(text || '', defaultOptions);
  }, [defaultOptions, text]);

  return (
    <div
      className={clsx('bc-xss', className)}
      dangerouslySetInnerHTML={{ __html: cleanText }}
    />
  );
};

export default Xss;
