import { useTranslation } from 'react-i18next';

import { Typo } from '@bluecodecom/ui';

import Prepay from '../Prepay';

const EmptyPrepay = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="flex flex-col px-4 space-y-4 text-center">
        <Typo weight="700" size="2xl">
          {t('prepay.noResults.title')}
        </Typo>
        <Typo weight="300">{t('prepay.noResults.description')}</Typo>
      </div>
      <Prepay showRecharge />
    </div>
  );
};

export default EmptyPrepay;
