import { FC, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { nativeService } from '@bluecodecom/native';

import Asset from '../../Asset';
import Button from '../../Button';
import Footer from '../../Footer';
import Layout from '../../Layout';
import Typo from '../../Typo';
import { ErrorProps } from './Error.types';

const Error: FC<ErrorProps> = ({
  title,
  subtitle,
  close,
  hideFooter,
  onClose,
}) => {
  const scrollContainer = useRef<HTMLDivElement>(null);

  const { t } = useTranslation('ui.pages');

  const handleClose = useCallback(() => {
    nativeService.ultimateWebviewClose();
  }, []);

  return (
    <Layout scrollContainer={scrollContainer}>
      <div
        className="flex flex-col flex-1 px-6 pt-4 pb-8 space-y-4 text-center mobileMD:px-8 mobileMD:space-y-6"
        data-testid="ui-error-page"
      >
        <Asset
          name="Cross"
          autoResizeViewHeight={40}
          imageClassName="max-h-none object-contain object-bottom"
          dropShadow
          isBouncing
          withTopMargin
        />
        <Typo
          size="2xl"
          weight="700"
          testId="ui-error-page-title"
          className="mobileMD:text-3xl"
        >
          {title || t('error.title')}
        </Typo>
        <Typo weight="300" testId="ui-error-page-subtitle">
          {subtitle || t('error.subtitle')}
        </Typo>
      </div>
      {!hideFooter && (
        <Footer container={scrollContainer}>
          <Button onClick={onClose || handleClose} testId="ui-error-page-close">
            {close || t('error.close')}
          </Button>
        </Footer>
      )}
    </Layout>
  );
};

export default Error;
