import type { SVGProps } from 'react';
import { memo } from 'react';

const SvgBankOutlineIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    data-testid="bank-outline-icon"
    viewBox="0 0 24 25"
    width="1.5rem"
    height="1.5rem"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="m12.37 2.4602 9 3.6c.35.14.63.56.63.93V10.31c0 .55-.45 1-1 1H3c-.55 0-1-.45-1-1V6.99c0-.37.28-.79.63-.93l9-3.6c.2-.08.54-.08.74 0ZM22 22.3101H2v-3c0-.55.45-1 1-1h18c.55 0 1 .45 1 1v3Zm-18-4v-7m4 7v-7m4 7v-7m4 7v-7m4 7v-7m-19 11h22"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M12 8.81c.8284 0 1.5-.6715 1.5-1.5 0-.8284-.6716-1.5-1.5-1.5s-1.5.6716-1.5 1.5c0 .8285.6716 1.5 1.5 1.5Z"
    />
  </svg>
);
const Memo = memo(SvgBankOutlineIcon);
export default Memo;
