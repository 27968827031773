const en = {
  'no-data': {
    title: 'No entries found',
    subtitle: 'There are currently no entries',
    close: 'Close',
  },
  error: {
    title: 'Oops, something went wrong there.',
    subtitle: 'Unfortunately there has been a problem, please try again.',
    close: 'Close',
  },
  'not-found': {
    title: 'Oops, something went wrong there.',
    subtitle: 'Unfortunately there has been a problem, please try again.',
    close: 'Close',
  },
};

export default en;
