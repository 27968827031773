const de = {
  'no-data': {
    title: 'Nessuna voce disponibile',
    subtitle: 'Non è stato possibile trovare alcuna voce',
    close: 'Chiudi',
  },
  error: {
    title: 'Ops, qualcosa è andato storto.',
    subtitle: 'Purtroppo si è verificato un problema, si prega di riprovare.',
    close: 'Chiudi',
  },
  'not-found': {
    title: 'Pagina non trovata.',
    subtitle: 'Purtroppo si è verificato un problema, si prega di riprovare.',
    close: 'Chiudi',
  },
  success: {
    title: 'Il successo',
    subtitle: 'La richiesta è andata a buon fine.',
    close: 'Chiudi',
  },
};

export default de;
