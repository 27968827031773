import { FC } from 'react';

import clsx from 'clsx';

import List from '../List';
import Typo from '../Typo';
import Item from './Item';
import { MenuComposition, MenuProps } from './Menu.types';

const Menu: FC<MenuProps> & MenuComposition = ({
  title,
  titleSize = 'base',
  children,
  className,
}) => (
  <div className={clsx('flex flex-col space-y-3', className)}>
    {title && <Typo size={titleSize}>{title}</Typo>}
    <List isDense>{children}</List>
  </div>
);

Menu.Item = Item;

export default Menu;
