import { useTranslation } from 'react-i18next';

import { Typo } from '@bluecodecom/ui';

import { ChevronLeftIcon } from '@bluecodecom/icons';

import { banksHooks } from '@bluecodecom/bank-search-webview/features/banks';

const BackButton = () => {
  const { t } = useTranslation();
  const { isParentSelected, onBackClick } = banksHooks.useBanksContext();

  if (!isParentSelected) {
    return null;
  }

  return (
    <div
      onClick={onBackClick}
      className="flex items-center px-4 space-x-1 text-blue-400 dark:text-gray-0"
    >
      <ChevronLeftIcon />
      <Typo color="text-inherit">{t('BankSearchForm.back')}</Typo>
    </div>
  );
};

export default BackButton;
