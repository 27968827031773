import { FC, ReactNode, useMemo } from 'react';

import clsx from 'clsx';

import { CheckIcon } from '@bluecodecom/icons';

import Button from './Button';
import Icon from './Icon';
import IconLogo from './IconLogo';
import { ListItemComposition, ListItemProps } from './Item.types';
import Label from './Label';
import Logo from './Logo';
import Subtitle from './Subtitle';
import Title from './Title';

const sizeClassName = {
  small: 'min-h-[3rem] px-3 py-2',
  medium: 'min-h-[4rem] p-3',
};

const isPrimitive = (el: ReactNode) =>
  ['string', 'number', 'boolean'].indexOf(typeof el) !== -1 || !el;

const Item: FC<ListItemProps> & ListItemComposition = ({
  size = 'medium',
  weight,
  label,
  children,
  title,
  buttonText,
  isActive,
  isSelected,
  disableInteractiveClassName,
  subtitle,
  logo,
  icons,
  className,
  style,
  info,
  testId,
  refFn,
  truncateTitle,
  truncateSubtitle,
  onClick,
}) => {
  const hasSubtitle = size === 'medium' && subtitle;

  const details = useMemo(() => {
    if (isSelected) {
      return <CheckIcon className="text-blue-500 dark:text-gray-0" />;
    }

    if (children) {
      return children;
    }

    if (label) {
      if (typeof label === 'string') {
        return <Item.Label>{label}</Item.Label>;
      }

      return label;
    }

    if (buttonText) {
      return <Item.Button>{buttonText}</Item.Button>;
    }

    return null;
  }, [buttonText, children, isSelected, label]);

  const hasBoldTitle = useMemo(() => {
    return Boolean(logo) || hasSubtitle || isSelected;
  }, [hasSubtitle, isSelected, logo]);

  return (
    <div
      onClick={onClick}
      style={style}
      data-testid={testId}
      ref={refFn}
      className={clsx(
        'bc-list-item relative flex w-full flex-col rounded-2xl shadow-md transition-colors dark:shadow-darkMd',
        !isActive && 'bg-gray-0 dark:bg-gray-500',
        isActive &&
          'border border-blue-400 bg-blue-50 dark:border-gray-300 dark:bg-blue-600',
        !disableInteractiveClassName &&
          'active:bg-gray-20 active:dark:bg-gray-600 hover:bg-blue-50 dark:hover:bg-gray-400 cursor-pointer',
        sizeClassName[size],
        className,
      )}
    >
      <div
        className={clsx('flex items-center flex-1', {
          'min-h-[2rem]': size === 'small',
          'min-h-[2.5rem]': size === 'medium',
        })}
      >
        {logo}
        <div className="flex flex-col justify-center flex-1 max-w-full pr-2 ml-1 space-y-1 overflow-hidden">
          {isPrimitive(title) ? (
            <Item.Title
              weight={hasBoldTitle ? '500' : weight || '300'}
              isTruncated={truncateTitle}
            >
              {title}
            </Item.Title>
          ) : (
            title
          )}
          {hasSubtitle &&
            (isPrimitive(subtitle) ? (
              <Item.Subtitle isTruncated={truncateSubtitle}>
                {subtitle}
              </Item.Subtitle>
            ) : (
              subtitle
            ))}
        </div>
        {details}
        {!isSelected && icons}
      </div>
      {info}
    </div>
  );
};

Item.Button = Button;
Item.Icon = Icon;
Item.Label = Label;
Item.Logo = Logo;
Item.Subtitle = Subtitle;
Item.Title = Title;
Item.IconLogo = IconLogo;

export default Item;
