import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { List } from '@bluecodecom/ui';

import { WalletAddOutlineIcon } from '@bluecodecom/icons';

import {
  BankItem,
  banksService,
} from '@bluecodecom/bank-search-webview/features/banks';
import { configService } from '@bluecodecom/bank-search-webview/features/config';

const CALIDA_BIC = 'PREPAYCALID';

const usePrepayBank = () => {
  const { t } = useTranslation();
  const onBankRedirect = useCallback(() => {
    if (!configService.config.prepay_bank?.id) {
      return;
    }
    document.location = `https://app.${configService.domain}/gateway/onboard/${configService.config.prepay_bank?.id}?jwt=${banksService.jwt}`;
  }, []);
  const calidaPrepaidBank: BankItem | null = useMemo(() => {
    if (
      !configService.hasCalidaPrepay() ||
      !configService.config.prepay_bank?.id
    ) {
      return null;
    }
    return {
      bic: CALIDA_BIC,
      blz: '',
      id: configService.config.prepay_bank?.id || '',
      logo: <List.Item.Logo icon={<WalletAddOutlineIcon />} />,
      name: t('prepay.card.createNewPrepaidAccount.title'),
    };
  }, [t]);

  if (!calidaPrepaidBank) {
    return { prepayBank: null, handleBankRedirect: onBankRedirect };
  }

  return {
    prepayBank: calidaPrepaidBank,
    handleBankRedirect: onBankRedirect,
  };
};

export default usePrepayBank;
