import clsx from 'clsx';

import { BluecodeTriangleLightBlue } from '@bluecodecom/icons';

import { IndicatorProps } from './Indicator.types';

const ICON_SIZE = {
  small: 24,
  normal: 40,
  big: 64,
};

const CONTAINER_SIZE = {
  small: 'w-6 h-6',
  normal: 'w-10 h-10',
  big: 'w-16 h-16',
};

const Indicator = ({
  className,
  iconClassName,
  image,
  size = 'normal',
  testId = 'loader-indicator',
}: IndicatorProps) => (
  <div
    data-testid={testId}
    className={clsx('relative', CONTAINER_SIZE[size], className)}
  >
    {image ? (
      <div className="relative w-full h-full overflow-hidden rounded-full ">
        <img
          alt=""
          className={clsx('animate-pulse', iconClassName)}
          src={image}
          width={ICON_SIZE[size]}
          height={ICON_SIZE[size]}
        />
      </div>
    ) : (
      <>
        <div className="absolute opacity-80">
          <div className="text-blue-400 animate-up dark:text-gray-0">
            <BluecodeTriangleLightBlue
              className={iconClassName}
              width={ICON_SIZE[size]}
              height={ICON_SIZE[size]}
            />
          </div>
        </div>
        <div className="absolute opacity-80">
          <div className="text-blue-400 animate-down dark:text-gray-0">
            <BluecodeTriangleLightBlue
              className={iconClassName}
              width={ICON_SIZE[size]}
              height={ICON_SIZE[size]}
            />
          </div>
        </div>
      </>
    )}
  </div>
);

export default Indicator;
