import type { SVGProps } from 'react';
import { memo } from 'react';

const SvgWalletAddOutlineIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    data-testid="wallet-add-outline-icon"
    viewBox="0 0 25 24"
    width="1.5rem"
    height="1.5rem"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M18.6245 7.05c-.24-.04-.49-.05-.75-.05h-10c-.28 0-.55.02-.81.06.14-.28.34-.54.58-.78l3.25-3.26c1.37-1.36 3.59-1.36 4.96 0l1.75 1.77c.64.63.98 1.43 1.02 2.26Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M9.873 19c0 .75-.21 1.46-.58 2.06-.69 1.16-1.96 1.94-3.42 1.94s-2.73-.78-3.42-1.94c-.37-.6-.58-1.31-.58-2.06 0-2.21 1.79-4 4-4s4 1.79 4 4Zm-2.5082-.0205h-2.98m1.4882-1.46v2.99"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M22.873 12v5c0 3-2 5-5 5h-9.37c.31-.26.58-.58.79-.94.37-.6.58-1.31.58-2.06 0-2.21-1.79-4-4-4-1.2 0-2.27.53-3 1.36V12c0-2.72 1.64-4.62 4.19-4.94.26-.04.53-.06.81-.06h10c.26 0 .51.01.75.05 2.58.3 4.25 2.21 4.25 4.95Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M22.873 12.5h-3c-1.1 0-2 .9-2 2s.9 2 2 2h3"
    />
  </svg>
);
const Memo = memo(SvgWalletAddOutlineIcon);
export default Memo;
