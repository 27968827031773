import type { SVGProps } from 'react';
import { memo } from 'react';

const SvgAccountNameIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    data-testid="account-name-icon"
    viewBox="0 0 24 24"
    width="1.5rem"
    height="1.5rem"
    {...props}
  >
    <path
      fill="currentColor"
      d="M16.91 20.3459a.7807.7807 0 0 0 0-.5875L10.7113 3.6405a.8106.8106 0 0 0-.2981-.3405.8055.8055 0 0 0-.8672 0 .8093.8093 0 0 0-.298.3405L3.052 19.7497a.7891.7891 0 0 0 .0157.6018.7848.7848 0 0 0 .4351.4145.7817.7817 0 0 0 .5997-.0156.7856.7856 0 0 0 .413-.4367l1.6477-4.277h7.6591l1.6507 4.277a.7777.7777 0 0 0 .2775.3592.7726.7726 0 0 0 .4302.1425.7727.7727 0 0 0 .4361-.1229.7771.7771 0 0 0 .2932-.3466ZM6.7485 14.471 9.968 6.0787l3.2516 8.3982-6.471-.0059ZM21.1245.0004a.7969.7969 0 0 0-.6468.3232.8538.8538 0 0 0-.1492.511V23.189a.818.818 0 0 0 .3039.6311.8116.8116 0 0 0 .6795.162.8276.8276 0 0 0 .4616-.2992.8336.8336 0 0 0 .1734-.5233V.8376a.8393.8393 0 0 0-.1521-.5082.7985.7985 0 0 0-.2944-.2482.7935.7935 0 0 0-.3759-.0808Z"
    />
  </svg>
);
const Memo = memo(SvgAccountNameIcon);
export default Memo;
