import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Typo } from '@bluecodecom/ui';

import OneCent from '../OneCent';
import Prepay from '../Prepay';
import EuTab from './EuTab';
import { EuTabProps } from './EuTab.types';

const EuTabContainer: FC<EuTabProps> = ({ isOneCent, isPrepay }) => {
  const { t } = useTranslation();
  if (isOneCent) {
    return (
      <div>
        <OneCent isSecondary={isPrepay} />
        {isPrepay && (
          <>
            <div className="flex items-center px-4 text-center">
              <div className="flex-1 h-px bg-gray-200 dark:bg-gray-500" />
              <Typo className="px-4">{t('oneCent.or')}</Typo>
              <div className="flex-1 h-px bg-gray-200 dark:bg-gray-500" />
            </div>
            <Prepay showRecharge />
          </>
        )}
      </div>
    );
  }
  return <EuTab />;
};

export default EuTabContainer;
