import {
  InitiateMerchantTokenPaymentProps,
  OpenWebviewOverlayProps,
  VasAssertionRequestProps,
} from './types';

class NativeService {
  private static instance: NativeService;

  private _isInOverlay = false;

  public get isInOverlay() {
    return this._isInOverlay;
  }

  public set isInOverlay(value) {
    this._isInOverlay = value;
  }

  public static getInstance() {
    if (!NativeService.instance) {
      NativeService.instance = new NativeService();
    }

    return new NativeService();
  }

  constructor() {
    window.addEventListener('message', (e: MessageEvent) => {
      if (typeof e.data !== 'object') {
        return;
      }

      const data = e.data as BluecodeNativeReceiveMessage;

      if (data.type !== 'BC_NATIVE_CB') {
        return;
      }

      switch (data.fn) {
        case 'isFullscreen': {
          if (
            typeof data.parameter === 'boolean' ||
            typeof data.parameter === 'string'
          ) {
            return window.isFullscreen?.(data.parameter);
          }

          break;
        }
        case 'externalSetLocation': {
          if (typeof data.parameter === 'object') {
            const { latitude, longitude } = data.parameter as any;

            if (typeof latitude === 'number' && typeof longitude === 'number') {
              return window.externalSetLocation?.(latitude, longitude);
            }
          }

          break;
        }
        case 'can_pay': {
          if (
            typeof data.parameter === 'boolean' ||
            typeof data.parameter === 'string'
          ) {
            return window.can_pay?.(data.parameter);
          }

          break;
        }
        case 'sdkLocked': {
          return window.sdkLocked?.();
        }
        case 'onVasAssertionResult': {
          if (typeof data.parameter === 'string') {
            return window.onVasAssertionResult?.(data.parameter);
          }

          break;
        }
        case 'receive_tracking_event': {
          if (typeof data.parameter === 'string') {
            return window.receive_tracking_event?.(
              data.parameter as BluecodeNativeTrackingEventType,
            );
          }

          break;
        }
        case 'setContractInfo': {
          if (typeof data.parameter === 'string') {
            return window.setContractInfo?.(data.parameter);
          }

          break;
        }
        case 'setHasSuggestionsEnabled': {
          if (typeof data.parameter === 'boolean') {
            return window.setHasSuggestionsEnabled?.(data.parameter);
          }

          break;
        }
        case 'setVasDeepLink': {
          if (typeof data.parameter === 'string') {
            return window.setVasDeepLink?.(data.parameter);
          }

          break;
        }
        case 'setVasHostConfig': {
          if (typeof data.parameter === 'string') {
            return window.setVasHostConfig?.(data.parameter);
          }

          break;
        }
        case 'setVasMerchant': {
          if (typeof data.parameter === 'string' || data.parameter === null) {
            return window.setVasMerchant?.(data.parameter);
          }

          break;
        }
        case 'set_loyalty_data': {
          if (typeof data.parameter === 'string') {
            return window.set_loyalty_data?.(data.parameter);
          }

          break;
        }
      }
    });
  }

  public isInIframe() {
    return window !== window.parent;
  }

  public runJsFunction(
    fn: BluecodeNativeFnName,
    parameter?: BluecodeNativeArg,
  ) {
    if (this.isInIframe()) {
      return window.parent.postMessage(
        { fn, parameter, type: 'BC_NATIVE_FN' } as BluecodeNativePostMessage,
        '*',
      );
    }

    try {
      if (window.webkit?.messageHandlers?.[fn]?.postMessage) {
        const pm = window.webkit.messageHandlers[fn]
          .postMessage as BluecodeNativeFn;

        return pm(parameter || 'run');
      }

      if (window.bluecode?.[fn]) {
        if (!parameter) {
          return window.bluecode[fn]();
        } else {
          if (typeof parameter === 'object') {
            parameter = JSON.stringify(parameter);
          }

          return window.bluecode[fn](parameter);
        }
      }

      console.log('The native context does not exist yet');
    } catch {
      console.log(`There was an issue with native context for function ${fn}`);
    }
  }

  public hasNativeJsFunction(fnName: BluecodeNativeFnName) {
    return Boolean(
      window.webkit?.messageHandlers[fnName] || window.bluecode?.[fnName],
    );
  }

  /**
   * Check if window object has webkit.messageHandlers or bluecode
   * objects. If yes we are running JS inside the native webview
   * @returns boolean
   */
  public isNativeEnvironment() {
    return Boolean(window.webkit?.messageHandlers || window.bluecode);
  }

  public askGeo() {
    this.runJsFunction('native_bc_ask_geo');
  }

  public appFocus() {
    this.runJsFunction('native_bc_app_focus');
  }

  public setVasFullscreen(isFullscreen: boolean) {
    this.runJsFunction(
      'native_bc_set_vas_fullscreen',
      isFullscreen ? 'true' : 'false',
    );
  }

  public isVasFullscreen() {
    this.runJsFunction('native_bc_is_vas_fullscreen');
  }

  public openBluecodeSettings() {
    this.runJsFunction('native_bc_open_bluecode_settings');
  }

  public minimizeWebview() {
    this.runJsFunction('native_bc_minimize_webview');
  }

  public vibrate() {
    this.runJsFunction('native_bc_vibrate');
  }

  public playSound(sound = 'notification') {
    this.runJsFunction('native_bc_play_sound', sound);
  }

  public openInternal(url: string) {
    this.runJsFunction('native_bc_open_internal', url);
  }

  public openExternal(url: string) {
    this.runJsFunction('native_bc_open_external', url);
  }

  public askLoyalty() {
    this.runJsFunction('native_bc_ask_loyalty');
  }

  public closeMiniApp() {
    this.runJsFunction('native_bc_close_miniapp');
  }

  public closeWebview() {
    this.runJsFunction('native_bc_close_webview');
  }

  public showNavigation() {
    this.runJsFunction('native_bc_show_navigation');
  }

  public hideNavigation() {
    this.runJsFunction('native_bc_hide_navigation');
  }

  public ultimateWebviewClose() {
    this.minimizeWebview();
    this.closeMiniApp();
    this.closeWebview();
  }

  public playSoundAndVibrate() {
    this.playSound();
    this.vibrate();
  }

  public openWebviewOverlay({
    url,
    title = null,
    miniAppId = null,
  }: OpenWebviewOverlayProps) {
    const options = {
      url,
      title,
      miniAppId,
      heightInPercent: null,
      options: null,
    };

    this.runJsFunction('native_bc_open_webview_overlay_with_url', options);
  }

  public initiateMerchantTokenPayment(
    props: InitiateMerchantTokenPaymentProps,
  ) {
    this.runJsFunction('native_bc_initiate_merchant_token_payment', props);
  }

  public startOnboarding() {
    this.runJsFunction('native_bc_start_onboarding');
  }

  public openHuaweiWalletPrivacyPage() {
    this.runJsFunction('open_huawei_wallet_privacy_page');
  }

  public refreshHistory() {
    this.runJsFunction('native_bc_refresh_history');
  }

  public requestReview() {
    this.runJsFunction('native_bc_request_review');
  }

  public shareString(message: string) {
    this.runJsFunction('native_bc_share_string', message);
  }

  public canPay() {
    this.runJsFunction('native_bc_can_pay');
  }

  public getSdkVersion() {
    const info = navigator.userAgent?.split('|');

    if (info.length !== 3) {
      return 'not new_webview';
    }

    const versionSearch = info[1].match(/\d+\.\d+\.\d+/g);

    if (!versionSearch) {
      return 'no version detected';
    }

    return versionSearch[0];
  }

  public isSdkAtLeast(compareToVersion: string) {
    const m = navigator.userAgent?.match(/BCSDK ([0-9]*)\.([0-9]*)\.([0-9]*)/);

    if (m) {
      const compareToEls = compareToVersion.split('.');
      const sdkEls = m.slice(1, 4);

      const toComparable = (els: string[]) =>
        els.map((el) => '000'.substring(el.length) + el).join('');

      return toComparable(sdkEls) >= toComparable(compareToEls);
    }

    return false;
  }

  public supportsMiniAppWebView() {
    return this.isSdkAtLeast('5.1.0');
  }

  public supportsOverlayHeaderHiding() {
    return (
      !nativeService.isNativeEnvironment() ||
      (nativeService.isSdkAtLeast('6.5.13') &&
        this.hasNativeJsFunction('native_bc_hide_navigation'))
    );
  }

  public hasCustomNavigation() {
    if (!this.supportsOverlayHeaderHiding()) {
      return false;
    }

    const url = window.location.href;

    return (
      url.includes('hasCustomNavigation=true') ||
      url.includes('display=with-back-button') ||
      url.includes('withCustomNavigation=true')
    );
  }

  public openUrl({ url, title = '', miniAppId }: OpenWebviewOverlayProps) {
    if (
      !this.isInOverlay &&
      (this.supportsMiniAppWebView() || this.isInIframe())
    ) {
      return this.openWebviewOverlay({
        url,
        miniAppId,
        title,
      });
    }

    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.click();
    anchor.remove();
  }

  public scanCode() {
    this.runJsFunction('native_bc_scan_code');
  }

  public setBadgeCount(count: number) {
    this.runJsFunction('native_bc_set_badge_count', count);
  }

  public requestSdkUnlock() {
    this.runJsFunction('native_bc_request_sdk_unlock');
  }

  public setMiniAppTrackingId(id: string) {
    this.runJsFunction('native_bc_set_mini_app_tracking_id', id);
  }

  public initiatePayment(props: InitiateMerchantTokenPaymentProps) {
    this.runJsFunction('native_bc_initiate_payment', props);
  }

  public vasAssertionRequest(props: VasAssertionRequestProps) {
    this.runJsFunction('native_bc_vas_assertion_request', props);
  }

  public navigateToMerchantSuggestions() {
    this.runJsFunction('native_bc_navigate_to_merchant_suggestions');
  }

  public allowResumeOnboarding() {
    this.runJsFunction('native_bc_allow_resume_onboarding');
  }

  public openExternalAndAllowResume(url: string) {
    this.runJsFunction('native_bc_open_external_allow_resume', url);
  }

  public denyResumeOnboarding() {
    this.runJsFunction('native_bc_deny_resume_onboarding');
  }

  public requestVasHostConfig() {
    if (!window.setVasHostConfig) {
      // TODO: Sentry error - not set
    }

    this.runJsFunction('native_bc_request_vas_host_config');
  }

  public requestContractInfo() {
    if (!window.setContractInfo) {
      // TODO: Sentry error - not set
    }

    this.runJsFunction('native_bc_request_current_contract_info');
  }

  public setLocalPushNotification(notification: LocalPushNotification) {
    this.runJsFunction(
      'native_bc_set_local_push_notification',
      JSON.stringify(notification),
    );
  }

  public cancelLocalPushNotification(identifier: string) {
    const params: CancelLocalPushNotificationDto = {
      identifier,
    };

    this.runJsFunction(
      'native_bc_cancel_local_push_notification',
      JSON.stringify(params),
    );
  }

  public allowResumeMiniApp() {
    this.runJsFunction('native_bc_allow_resume_mini_app');
  }

  public denyResumeMiniApp() {
    this.runJsFunction('native_bc_deny_resume_mini_app');
  }

  public setOnboardingTrackingEvent(eventType: OnboardingTrackingEventType) {
    const params: OnboardingTrackingEvent = {
      eventType,
    };

    this.runJsFunction(
      'native_bc_set_onboarding_tracking_event',
      JSON.stringify(params),
    );
  }

  public setContentDeepLink(params: ContentDeepLinkDto) {
    this.runJsFunction(
      'native_bc_set_content_deep_link',
      JSON.stringify(params),
    );
  }
}

const nativeService = NativeService.getInstance();

export default nativeService;
