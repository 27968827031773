import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, List, Typo } from '@bluecodecom/ui';

import { BankOutlineIcon } from '@bluecodecom/icons';

import { banksService } from '@bluecodecom/bank-search-webview/features/banks';
import { configService } from '@bluecodecom/bank-search-webview/features/config';
import { trackingService } from '@bluecodecom/bank-search-webview/features/tracking';

import { OneCentProps } from './OneCent.types';

const OneCent: FC<OneCentProps> = ({ isSecondary }) => {
  const { t } = useTranslation();

  const handleClick = useCallback(() => {
    if (!configService.config.region_eu_bank?.id) {
      return;
    }

    trackingService.trackEvent('bank-search bank-selected eu', {
      id: configService.config.region_eu_bank.id,
    });

    document.location = `https://app.${configService.domain}/gateway/onboard/${configService.config.region_eu_bank.id}?jwt=${banksService.jwt}`;
  }, []);
  return (
    <div className="p-4">
      <List.Item
        disableInteractiveClassName
        logo={
          <BankOutlineIcon className="flex-shrink-0 w-8 h-8 mr-2 dark:text-gray-0" />
        }
        title={
          <Typo className="leading-tight" size="xl" weight="700">
            {t('oneCent.title')}
          </Typo>
        }
        info={
          <div className="flex flex-col pt-4 space-y-4">
            <Typo weight="300">{t('oneCent.description1')}</Typo>
            <Typo weight="300">{t('oneCent.description2')}</Typo>
            <Button
              variant={isSecondary ? 'secondary' : 'primary'}
              onClick={handleClick}
              className="!h-10 !rounded-xl"
            >
              {t('oneCent.title')}
            </Button>
          </div>
        }
      />
    </div>
  );
};

export default OneCent;
