import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import clsx from 'clsx';

import { useThemeContext } from '@bluecodecom/theme';

import { nativeService } from '@bluecodecom/native';

import { ChevronLeftIcon, CloseIcon } from '@bluecodecom/icons';

import Button from '../Button';
import Typo from '../Typo';
import { NavbarProps } from './Navbar.types';

const Navbar: FC<NavbarProps> = ({
  showBackButton,
  showCloseButton,
  positionClassName = 'fixed',
  hideBackButtonLabel,
  title,
  onBackClick,
  onCloseClick,
}) => {
  const { t } = useTranslation('ui.navbar');
  const { isDarkMode } = useThemeContext();

  const handleBackClick = useCallback(() => {
    if (onBackClick) {
      return onBackClick();
    }

    window.history.back();
  }, [onBackClick]);

  const handleClose = useCallback(() => {
    if (onCloseClick) {
      return onCloseClick();
    }

    nativeService.ultimateWebviewClose();
  }, [onCloseClick]);

  if (
    !nativeService.supportsOverlayHeaderHiding() ||
    (!showBackButton && !showCloseButton)
  ) {
    return null;
  }

  return (
    <div
      className={clsx(
        'top-0 z-50 flex items-center justify-between w-full px-4 pt-4',
        positionClassName,
      )}
    >
      {showBackButton ? (
        <Button
          onClick={handleBackClick}
          variant={isDarkMode ? 'primary-dark' : 'primary-white'}
          className={clsx(
            '!rounded-full shadow-lg dark:shadow-darkLg',
            {
              '!text-gray-0': isDarkMode,
              '!text-gray-600': !isDarkMode,
            },
            {
              '!pl-1': !hideBackButtonLabel,
              '!w-8 !h-8': hideBackButtonLabel,
            },
          )}
          size="small"
          icon={<ChevronLeftIcon />}
        >
          {!hideBackButtonLabel ? t('back') : null}
        </Button>
      ) : (
        <div />
      )}
      {title && (
        <div
          className={clsx('absolute truncate flex justify-center', {
            'left-0 right-0': !showBackButton && !showCloseButton,
            'inset-x-14':
              (showCloseButton && !showBackButton) || hideBackButtonLabel,
            'inset-x-28': showBackButton && !hideBackButtonLabel,
          })}
        >
          <Typo className="truncate" size="2xl">
            {title}
          </Typo>
        </div>
      )}
      {showCloseButton && (
        <Button
          onClick={handleClose}
          size="small"
          variant={isDarkMode ? 'primary-dark' : 'primary-white'}
          className={clsx(
            '!rounded-full !w-8 !h-8 shadow-lg dark:shadow-darkLg ',
            {
              '!text-gray-0': isDarkMode,
              '!text-gray-600': !isDarkMode,
            },
          )}
          icon={<CloseIcon />}
        />
      )}
    </div>
  );
};
export default Navbar;
