import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { List, Typo } from '@bluecodecom/ui';

import { banksHooks } from '@bluecodecom/bank-search-webview/features/banks';
import { configService } from '@bluecodecom/bank-search-webview/features/config';
import { usePrepayBank } from '@bluecodecom/bank-search-webview/features/config/hooks';

import EmptyPrepay from '../EmptyPrepay';
import Empty from './Empty';
import Item from './Item';
import NotEligible from './NotEligible';
import Partners from './Partners';

const PARTNERS_SDK_HOSTS: string[] = [];
const CALIDA_BIC = 'PREPAYCALID';

const BanksList = () => {
  const { t } = useTranslation();
  const {
    banks,
    search,
    isInitialLoading,
    isLoading,
    isParentSelected,
    currentCountry,
  } = banksHooks.useBanksContext();

  const { prepayBank } = usePrepayBank();

  const filteredBanks = useMemo(() => {
    if (!banks) {
      return banks;
    }
    return banks.filter((b) => b.bic !== CALIDA_BIC);
  }, [banks]);

  const canShowPartners = useMemo(
    () =>
      PARTNERS_SDK_HOSTS.includes(configService.config.sdk_host || '') &&
      ['AT', 'DE'].includes(currentCountry?.toString() || ''),
    [currentCountry],
  );

  const showPrepay = useMemo(() => configService.hasCalidaPrepay(), []);

  const title = useMemo(() => {
    if (isParentSelected && !search.length) {
      return null;
    }

    if (search.length) {
      return t('banks.title.searching');
    }

    return t('banks.title.default');
  }, [isParentSelected, search.length, t]);

  if (!isInitialLoading) {
    if (!banks && !isLoading) {
      return canShowPartners ? <Partners /> : <NotEligible />;
    }

    if (banks && !banks.length) {
      return showPrepay ? <EmptyPrepay /> : <Empty />;
    }
  }

  return (
    <div className="flex flex-col flex-1 ">
      {prepayBank && (
        <div className="flex flex-col px-4 pb-8 space-y-2">
          <Typo
            loadingClassName="w-1/4"
            isLoading={isInitialLoading}
            className=""
          >
            {t('prepay.card.rechargeTitle')}
          </Typo>

          <List isDense loadingItemsCount={1} isLoading={isInitialLoading}>
            <Item
              subtitle={t('prepay.card.createNewPrepaidAccount.description')}
              bank={prepayBank}
            />
          </List>
        </div>
      )}

      <div className="flex flex-col px-4 pb-8 space-y-2">
        {title && (
          <Typo
            loadingClassName="w-1/4"
            isLoading={isInitialLoading}
            className=""
          >
            {title}
          </Typo>
        )}

        <List isDense loadingItemsCount={5} isLoading={isInitialLoading}>
          {filteredBanks?.map((b, idx) => <Item key={idx} bank={b} />)}
        </List>
      </div>
    </div>
  );
};

export default BanksList;
