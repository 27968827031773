import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Button, Typo } from '@bluecodecom/ui';

import { WalletAddOutlineIcon } from '@bluecodecom/icons';

import { usePrepayBank } from '@bluecodecom/bank-search-webview/features/config/hooks';

import Item from './Item';
import { PrepayProps } from './Prepay.types';

const Prepay: FC<PrepayProps> = ({ showRecharge }) => {
  const { t } = useTranslation();

  const { handleBankRedirect } = usePrepayBank();

  const isIOS = navigator.userAgent.includes('iOS');

  return (
    <div className="p-4">
      <div className="p-4 bg-blue-100 shadow-md dark:bg-blue-500/50 rounded-2xl">
        <div className="flex items-center space-x-3">
          <WalletAddOutlineIcon className="flex-shrink-0 w-8 h-8 dark:text-gray-0" />
          <div>
            <Typo size="sm" weight="300">
              {t('prepay.card.recommended')}
            </Typo>
            <Typo size="xl" weight="700">
              {t(
                showRecharge
                  ? 'prepay.card.rechargeTitle'
                  : 'prepay.card.title',
              )}
            </Typo>
          </div>
        </div>
        <div className="mt-4 space-y-2">
          <Item>
            <Typo weight="300">
              <Trans i18nKey="prepay.card.features.f1">
                <Typo size="xs" weight="300" />
              </Trans>
            </Typo>
          </Item>

          <Item>
            <Typo weight="300">
              <Trans i18nKey="prepay.card.features.f2">
                <Typo size="xs" weight="300" />
              </Trans>
            </Typo>
          </Item>

          <Item>
            <div>
              <Typo weight="300">
                {t('prepay.card.features.f3')}
                {isIOS && (
                  <span className="text-xs">
                    {t('prepay.card.features.f3Ios')}
                  </span>
                )}
              </Typo>
            </div>
          </Item>

          <Item>
            <Typo weight="300">
              <Trans i18nKey="prepay.card.features.f4">
                <Typo size="xs" weight="300" />
              </Trans>
            </Typo>
          </Item>
        </div>
        <Button
          fullWidth
          className="!h-10 mt-4 !rounded-xl"
          onClick={handleBankRedirect}
        >
          {t('prepay.card.button')}
        </Button>
      </div>
    </div>
  );
};

export default Prepay;
