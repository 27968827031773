import { FC, PropsWithChildren } from 'react';

import { CheckIcon } from '@bluecodecom/icons';

const Item: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="flex items-center space-x-3">
      <div className="flex items-center justify-center flex-shrink-0 w-8 h-6 rounded-full bg-gray-0">
        <CheckIcon />
      </div>
      {children}
    </div>
  );
};

export default Item;
