import { ComponentProps } from 'react';

import List from '../../List';

const Item = ({
  size = 'small',
  children,
  label,
  ...props
}: ComponentProps<typeof List.Item>) => (
  <List.Item
    size={size}
    {...props}
    label={<List.Item.Label withForward>{label}</List.Item.Label>}
  >
    {children}
  </List.Item>
);

export default Item;
