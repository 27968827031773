import { useMemo } from 'react';

import { List } from '@bluecodecom/ui';

import { ForwardIcon } from '@bluecodecom/icons';

import { banksService } from '@bluecodecom/bank-search-webview/features/banks';
import { banksHooks } from '@bluecodecom/bank-search-webview/features/banks';

import { ItemProps } from './Item.types';

const Item = ({ bank, subtitle }: ItemProps) => {
  const { duplicatedNames, onBankSelect } = banksHooks.useBanksContext();

  const hasDuplication = duplicatedNames.includes(bank.name);

  const subtitleElement = useMemo(() => {
    if (subtitle) {
      return subtitle;
    }

    if (hasDuplication) {
      return (
        <List.Item.Subtitle
          isTruncated={false}
        >{`${bank.bic} / ${bank.blz}`}</List.Item.Subtitle>
      );
    }

    return '';
  }, [bank.bic, bank.blz, hasDuplication, subtitle]);

  return (
    <List.Item
      key={banksService.getBankKey(bank)}
      logo={
        bank.logo && typeof bank.logo === 'string' ? (
          <List.Item.Logo url={bank.logo} />
        ) : (
          bank.logo
        )
      }
      title={<List.Item.Title isTruncated={false}>{bank.name}</List.Item.Title>}
      subtitle={subtitleElement}
      onClick={onBankSelect(bank)}
      icons={<ForwardIcon className="text-gray-600 dark:text-gray-0" />}
    />
  );
};

export default Item;
