import {
  forwardRef,
  MouseEventHandler,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Transition, TransitionChild } from '@headlessui/react';
import clsx from 'clsx';

import mergeRefs from '@bluecodecom/utils/mergeRefs';

import { CancelIcon } from '@bluecodecom/icons';

import useDragDrawer from './Drawer.hook';
import DrawerPortal from './Drawer.portal';
import { DrawerProps } from './Drawer.types';
import useLockBodyScroll from './useBodyScrollLock';

const Drawer = forwardRef<HTMLDivElement, PropsWithChildren<DrawerProps>>(
  (
    {
      show,
      appear,
      fullHeight,
      children,
      appRoot,
      fullWidth,
      swipeable,
      withShadow,
      hideBackdrop,
      hideCloseIcon,
      closeIcon: incomingCloseIcon,
      heightClassName = 'h-[calc(100%_-_2.5rem)] rounded-t-2xl',
      innerClassName,
      containerClassName,
      disableBodyScrollLock,
      className,
      style,
      swipeButtonStyle,
      onClose,
      afterLeave,
      expandedDragAreaHeight,
      displayPosition = 'fixed',
    },
    ref,
  ) => {
    const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(
      null,
    );

    const showTransitionTimeoutRef = useRef<ReturnType<typeof setTimeout>>();

    const [childRef, setChildRef] = useState<HTMLDivElement | null>(null);

    useEffect(() => {
      if (show === false) {
        setShowTransitions(true);
      }
    }, [show]);

    const onCloseEvent = useCallback(() => {
      setShowTransitions(true);
      onClose?.();
    }, [onClose]);

    const { drawerStyles, isDragging, bind } = useDragDrawer({
      containerRef,
      onClose: onCloseEvent,
    });

    const [canExpandDragArea, setCanExpandDragArea] = useState(false);

    const scrollLockEnabled = !disableBodyScrollLock && show;

    const [showTransitions, setShowTransitions] = useState(true);

    useLockBodyScroll(scrollLockEnabled);

    const handleClose: MouseEventHandler<HTMLDivElement> = useCallback(
      (e) => {
        e.preventDefault();
        onCloseEvent?.();
      },
      [onCloseEvent],
    );

    const handleAfterLeave = useCallback(() => {
      afterLeave?.();
    }, [afterLeave]);

    useEffect(() => {
      clearTimeout(showTransitionTimeoutRef.current);

      if (show) {
        showTransitionTimeoutRef.current = setTimeout(() => {
          setShowTransitions(false);
        }, 700);
      }
    }, [show]);

    useEffect(() => {
      if (!swipeable || !expandedDragAreaHeight || !childRef) return;

      let scheduledAnimationFrame = false;

      const handleScroll = () => {
        if (scheduledAnimationFrame) {
          return;
        }

        scheduledAnimationFrame = true;

        requestAnimationFrame(() => {
          // Allow to only expand drag area when user scrolled to the top
          if (childRef.scrollTop === 0) {
            setCanExpandDragArea(true);
          } else {
            setCanExpandDragArea(false);
          }

          scheduledAnimationFrame = false;
        });
      };

      // Run on mount so it shows for initial render the
      // border if there is anything to scroll
      handleScroll();

      childRef.addEventListener('scroll', handleScroll);

      return () => childRef.removeEventListener('scroll', handleScroll);
    }, [childRef, expandedDragAreaHeight, swipeable]);

    const closeIcon = useMemo(() => {
      if (hideCloseIcon) {
        return null;
      }

      return (
        incomingCloseIcon || (
          <div
            className="absolute top-0 right-0 z-20 p-4 text-gray-500 cursor-pointer dark:text-gray-300"
            onClick={handleClose}
            data-testid="drawer-close"
          >
            <CancelIcon width={16} height={16} />
          </div>
        )
      );
    }, [handleClose, hideCloseIcon, incomingCloseIcon]);

    const dragHeight = canExpandDragArea ? expandedDragAreaHeight : 'auto';

    return (
      <DrawerPortal appRoot={appRoot}>
        <Transition appear={appear} show={show}>
          {!hideBackdrop && (
            <TransitionChild
              className={clsx(
                'top-0 left-0 right-0 bottom-0 z-30 bg-gray-600/30 dark:bg-gray-600/50',
                displayPosition,
              )}
              as="div"
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            />
          )}
          <TransitionChild
            as="div"
            className={clsx(
              'bottom-0 right-0 left-0 z-50 mx-auto w-full bg-gray-100 text-gray-600 will-change-transform dark:bg-gray-600 dark:text-gray-0',
              displayPosition,
              fullHeight ? 'h-full' : heightClassName,
              !fullWidth && 'max-w-screen-sm',
              withShadow &&
                'drop-shadow-drawerLight dark:drop-shadow-drawerDark',
              className,
            )}
            enter={clsx(
              showTransitions &&
                'transition-transform ease-in-out duration-300',
            )}
            leave="transition-transform ease-in-out duration-300 will-change-transform"
            enterFrom="translate-y-full"
            enterTo="translate-y-0"
            leaveFrom="translate-y-0"
            leaveTo="translate-y-full"
            afterLeave={handleAfterLeave}
            style={{ ...style, ...drawerStyles }}
          >
            <div
              ref={mergeRefs([ref, setContainerRef])}
              className={clsx(
                'relative flex h-full w-full pt-12 shadow-xl',
                containerClassName,
              )}
            >
              {swipeable && (
                <div
                  {...bind()}
                  style={{ height: dragHeight }}
                  className="absolute top-0 left-0 z-20 w-full px-4 py-2 pb-10 text-center touch-none"
                >
                  <div
                    style={swipeButtonStyle}
                    className="h-1 mx-auto bg-gray-300 w-14 rounded-2xl"
                  />
                </div>
              )}
              {closeIcon}
              <div
                ref={setChildRef}
                className={clsx(
                  'h-full w-full overflow-y-auto z-10',
                  isDragging && 'pointer-events-none',
                  innerClassName,
                )}
              >
                {children}
              </div>
            </div>
          </TransitionChild>
        </Transition>
      </DrawerPortal>
    );
  },
);

export default Drawer;
