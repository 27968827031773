import {
  FocusEventHandler,
  forwardRef,
  useCallback,
  useMemo,
  useState,
} from 'react';

import clsx from 'clsx';

import { CloseIcon, SearchIcon } from '@bluecodecom/icons';

import Loader from '../Loader';
import Typo from '../Typo';
import { SearchBarProps } from './SearchBar.types';

const SearchBar = forwardRef<HTMLInputElement, SearchBarProps>(
  (
    {
      label,
      value,
      className,
      heightClassName = 'h-14',
      helperText,
      isLoading,
      endIcon: incomingEndIcon,
      testId = 'search-bar',
      onFocus,
      onBlur,
      onChange,
      onClear,
    },
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleFocus: FocusEventHandler<HTMLInputElement> = useCallback(
      (e) => {
        setIsFocused(true);
        onFocus?.(e);
      },
      [onFocus],
    );

    const handleBlur: FocusEventHandler<HTMLInputElement> = useCallback(
      (e) => {
        setIsFocused(false);
        onBlur?.(e);
      },
      [onBlur],
    );

    const hasEndIcon = useMemo(
      () => !incomingEndIcon && !!(value?.length || isLoading),
      [incomingEndIcon, isLoading, value?.length],
    );

    const endIcon = useMemo(() => {
      if (isLoading) {
        return (
          <Loader
            type="spinner"
            className="w-6 h-6"
            colorClassName="text-blue-400 dark:text-gray-0"
          />
        );
      }

      return (
        <CloseIcon
          onClick={onClear}
          data-testid={`${testId}-clear`}
          className="cursor-pointer"
        />
      );
    }, [isLoading, onClear, testId]);

    return (
      <div
        className={clsx('flex flex-col space-y-2 text-left', className)}
        data-testid={`${testId}-container`}
      >
        <div
          className={clsx(
            'box-content relative flex overflow-hidden transition-colors rounded-2xl shadow-insetMd dark:shadow-darkInsetMd',
            heightClassName,
            isFocused || value
              ? 'bg-gray-0 dark:bg-gray-500'
              : 'bg-gray-100 dark:bg-gray-600',
          )}
        >
          <div className="absolute inset-y-0 flex items-center w-6 text-gray-500 left-4 dark:text-gray-200">
            <SearchIcon />
          </div>
          <input
            ref={ref}
            value={value}
            placeholder={label}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={onChange}
            data-testid={`${testId}-input`}
            className={clsx(
              'h-full w-full flex-1 bg-transparent font-primary text-base font-light leading-5 outline-none disabled:cursor-not-allowed text-gray-600 dark:text-gray-100 placeholder:text-gray-300 dark:placeholder:text-gray-200 placeholder:font-light pl-12',
              value ? 'pr-12' : '',
            )}
          />
          {incomingEndIcon}
          {hasEndIcon && (
            <div className="absolute inset-y-0 flex items-center w-6 text-gray-500 right-4 dark:text-gray-100">
              {endIcon}
            </div>
          )}
        </div>
        {helperText && (
          <Typo
            size="sm"
            weight="300"
            color="text-gray-500 dark:text-gray-0"
            data-testid={`${testId}-helper-text`}
          >
            {helperText}
          </Typo>
        )}
      </div>
    );
  },
);

export default SearchBar;
