import { FC } from 'react';

import clsx from 'clsx';

import ButtonGroup from './ButtonGroup';
import { ButtonsGroupContext } from './ButtonsGroup.provider';
import {
  ButtonsGroupComposition,
  ButtonsGroupProps,
} from './ButtonsGroup.types';

const ButtonsGroup: FC<ButtonsGroupProps> & ButtonsGroupComposition = ({
  children,
  selected,
  className,
}) => {
  return (
    <ButtonsGroupContext.Provider value={{ selected }}>
      <div
        className={clsx(
          'flex rounded-lg bg-gray-200 p-1 dark:bg-gray-400',
          className,
        )}
      >
        {children}
      </div>
    </ButtonsGroupContext.Provider>
  );
};

ButtonsGroup.Button = ButtonGroup;

export default ButtonsGroup;
