import { FC, useContext } from 'react';

import clsx from 'clsx';

import Button from '../Button/Button';
import { ButtonGroupProps } from './ButtonGroup.types';
import { ButtonsGroupContext } from './ButtonsGroup.provider';

const ButtonGroup: FC<ButtonGroupProps> = (props) => {
  const { selected } = useContext(ButtonsGroupContext);

  if (selected === props.name) {
    return (
      <Button
        {...props}
        className={clsx(
          'h-10 flex-1 flex-shrink-0 !rounded-lg',
          !props.disabled &&
            '!border-gray-0 !bg-gray-0 !text-gray-600 dark:!border-gray-600 dark:!bg-gray-600 dark:!text-gray-0',
        )}
      >
        {props.children}
      </Button>
    );
  }

  return (
    <Button
      {...props}
      variant="tertiary"
      className={clsx(
        '!dark:text-gray-0 h-10 flex-1 flex-shrink-0 !rounded-lg !text-gray-600',
        props.disabled && 'dark:!text-gray-0/30',
      )}
    >
      {props.children}
    </Button>
  );
};

export default ButtonGroup;
