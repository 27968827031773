import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Tabs } from '@bluecodecom/ui';

import { banksHooks } from '@bluecodecom/bank-search-webview/features/banks';
import {
  ConfigCountry,
  configService,
} from '@bluecodecom/bank-search-webview/features/config';

const Countries = () => {
  const { t } = useTranslation();
  const { currentCountry, hasCountryPicker, onCountryChange } =
    banksHooks.useBanksContext();

  const handleTabClick = useCallback(
    (country: ConfigCountry) => () => {
      onCountryChange(country);
    },
    [onCountryChange],
  );

  const showEu = !!configService.config.region_eu_bank?.id;

  if (!currentCountry || !hasCountryPicker) {
    return null;
  }

  return (
    <div className="z-50 px-4 mb-4 pointer-events-auto">
      <Tabs selected={currentCountry}>
        <Tabs.Item
          name="AT"
          label={t('BankSearchForm.countries.austria')}
          onClick={handleTabClick('AT')}
          testId="country-selector-AT"
        />
        <Tabs.Item
          name="DE"
          label={t('BankSearchForm.countries.germany')}
          onClick={handleTabClick('DE')}
          testId="country-selector-DE"
        />
        {showEu ? (
          <Tabs.Item
            name="EU"
            label={t('BankSearchForm.countries.eu')}
            onClick={handleTabClick('EU')}
            testId="country-selector-EU"
          />
        ) : null}
      </Tabs>
    </div>
  );
};

export default Countries;
