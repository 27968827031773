import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Asset, Typo } from '@bluecodecom/ui';

const NotEligible: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="flex-1 px-4 overflow-auto scrollbar-hide">
      <div className="flex flex-col space-y-2 text-center justify-center">
        <Typo weight="700" size="2xl">
          {t('banks.not-eligible.title')}
        </Typo>
        <Typo weight="300">{t('banks.not-eligible.subtitle')}</Typo>
        <Asset
          name="Bank"
          dropShadow
          isBouncing
          disableAutoResize
          containerClassName="h-[10rem]"
          imageClassName="!max-h-full w-[100px] h-[100px]"
        />
      </div>
    </div>
  );
};

export default NotEligible;
