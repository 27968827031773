const de = {
  'no-data': {
    title: 'Keine Einträge vorhanden',
    subtitle: 'Es konnten keine Einträge gefunden werden',
    close: 'Schließen',
  },
  error: {
    title: 'Ups, da ist etwas schief gelaufen.',
    subtitle: 'Leider hat es ein Problem gegeben, bitte versuche es erneut.',
    close: 'Schließen',
  },
  'not-found': {
    title: 'Seite nicht gefunden.',
    subtitle: 'Leider hat es ein Problem gegeben, bitte versuche es erneut.',
    close: 'Schließen',
  },
  success: {
    title: 'Erfolg.',
    subtitle: 'Ihre Anfrage war erfolgreich.',
    close: 'Schließen',
  },
};

export default de;
